export default class PrivatePolicy {
	private static key = 'privatepolicy';

	private static url_info = '/privatepolicy/?action=show';


	public static discover($parent?: JQuery): void {
		$('.js-privatepolicybutton', $parent).click((e) => {
			e.preventDefault();

			let $t = $(e.target);
			let type = $t.data('type');

			let url = this.url_info + '&type=' + type;

			(<any> $).fancybox({
				href: url
			});
		});
	}

	public static init(): void {
		$(() => {
			var $box = $('.privatepolicy');

			if (localStorage.getItem('privatepolicy') == '1') {
				$box.remove();
			} else {
				$.get('/privatepolicy/?action=info', (data) => {
					$('.privatepolicy__text', $box).html(data);

					$box.show();
				});

				$('.privatepolicy__accept', $box).click((e) => {
					e.preventDefault();

					localStorage.setItem('privatepolicy', '1');

					$box.fadeOut(200, () => {
						$box.remove();
					});
				});
			}
		});
	}
}
