import PrivatePolicy from "./utils/PrivatePolicy";

class Site {
	public static PrivatePolicy = PrivatePolicy;

	private static initHtml(): void {
		var $mainmenu = $('#mainmenu');

		$('.menubutton', $mainmenu).click((e) => {
			$mainmenu.toggleClass('opened', $mainmenu.hasClass('opened'));
		});
	}

	public static discover($parent?: JQuery): void {
		(<any> $('.lightbox, .fancybox, a[rel="lightbox"]', $parent)).fancybox({
			titlePosition: 'over'
		});

		PrivatePolicy.discover($parent);
	}

	public static init(): void {
		(<any> window).Site = Site;

		PrivatePolicy.init();

		$(() => {
			this.discover();
		});
	}
}

Site.init();
